import React from 'react'
import { Link } from 'gatsby'
import Helmet from 'react-helmet'
import Layout from '../../layouts'
import { logo } from 'images/over-ons'

const AlgemeneVoorwaardenPage = ({ location}) => (
  <Layout location={location}>
    <main className="over-ons">

      <Helmet>
        <meta name="robots" content="noindex,follow" />
      </Helmet>

      <header className="header padding-xl">
        <div className="container-md">
          <div className="grid yg center text-center">
            <div className="col-12">
              <img src={logo} alt="Employes logo large" className="margin-s-bottom" />
              <p className="streamer large wide center">Salarisadministratie en HR. Alles online, alles op 1 plek.</p>
            </div>
          </div>
        </div>
      </header>

      <section className="mission padding-xl">
        <div className="container-md">
          <div className="grid yg center">
            <div className="col-12 margin-xl-bottom">

              <h2>Algemene voorwaarden</h2>
              <p className="margin-m-bottom">Employes is simpel in gebruik. Daarom zijn onze algemene voorwaarden ook zo simpel mogelijk. Heb je er vragen over? Stel ze ons gerust via info@employes.nl.
              </p>



              <h3>1. Wie is wie?</h3>
              <p className="margin-m-bottom">Als je in deze algemene voorwaarden ‘je’ of ‘jou(w)’ leest, bedoelen we jou als opdrachtgever van Employes of als gebruiker van ons platform. Je bent gebruiker als je bijvoorbeeld als werknemer inlogt om je loonstroken in te zien of je gegevens te wijzigen.
                <br /><br />
                  Lees je ‘we’, ‘ons’ of ‘onze’, dan bedoelen we  Employes B.V. als opdrachtnemer en als aanbieder van het platform Employes. Employes B.V. is ingeschreven bij de Kamer van Koophandel onder nummer 64254615, en gevestigd aan de Rigakade 10, 1013 BC te Amsterdam.
              </p>

              <h3>2. Wanneer gelden deze voorwaarden?</h3>
              <p className="margin-m-bottom">Deze voorwaarden gelden voor alle offertes die we je sturen en alle overeenkomsten die we met je sluiten. Ook gelden deze voorwaarden voor iedereen die van Employes of van het proefaccount van Employes gebruikmaakt.
                <br /><br />
                  Afwijken van deze algemene voorwaarden kan alleen als we dat schriftelijk met elkaar overeenkomen.
                <br /><br />
                  Door Employes te gebruiken geef je aan dat je de inhoud van deze algemene voorwaarden kent en hiermee akkoord gaat.
              </p>

              <h3>3. Hoe komt onze overeenkomst tot stand?</h3>
              <p className="margin-m-bottom">Je kan online bij Employes een account aanmaken. Als je dit doet, ga je akkoord met onze voorwaarden. Zo komt de overeenkomst voor het gebruik van Employes tot stand.
              </p>

              <h3>4. Welke tarieven gelden er?</h3>
              <p className="margin-m-bottom">Op onze website lees je per pakket welk tarief ervoor geldt en welke functionaliteiten erin zitten. Naast een vast abonnementsbedrag brengen we een bedrag in rekening voor elke medewerker waarvoor je loonaangifte doet. Dit is ook het geval als je een nihil aangifte doet.
                <br /><br />
                  Als je een proefaccount aanmaakt, kan je Employes tot het moment van activatie kosteloos gebruiken. Door het doen van de eerste loonaangifte gaat de betaalverplichting in.
                <br /><br />
                  Alle genoemde prijzen zijn exclusief btw.
              </p>

              <h3>5. Hoe werkt de betaling en wat gebeurt er als je te laat betaalt?</h3>
              <p className="margin-m-bottom">De betalingstermijn van elke factuur is 21 dagen, tenzij er iets anders op de factuur vermeld staat.
                <br /><br />
                  Je betaalt de factuur per automatische incasso. Lukt de incasso niet, dan sturen we je een betaalverzoek.
                <br /><br />
                  Betaal je te laat? Dan kunnen we wettelijke rente en incassokosten in rekening brengen. Ook hebben we het recht om de overeenkomst te ontbinden of bepaalde functionaliteiten te blokkeren, totdat je betaald hebt. Dit kondigen we altijd eerst aan met een ‘ingebrekestelling’.
              </p>

              <h3>6. Hoe gaan we om met prijswijzigingen?</h3>
              <p className="margin-m-bottom">Het kan zijn dat we onze prijzen moeten wijzigen. Bijvoorbeeld omdat we functionaliteiten toevoegen of om de prijzen te corrigeren met de inflatiecorrectie.
                <br /><br />
                  Als we de prijzen wijzigen, laten we je dit uiterlijk 3 maanden van tevoren weten. Ben je het niet eens met de prijswijziging? Dan kan je de overeenkomst opzeggen. Als je Employes na de prijswijziging blijft gebruiken, ga je akkoord met de wijziging.
                <br /><br />
                  Als je op basis van een jaarfactuur betaalt, dan kan het zijn dat we het extra bedrag van de prijswijziging op basis van nacalculatie aan je doorberekenen.
              </p>

              <h3>7. Wanneer kan je opzeggen? Wanneer kunnen we opzeggen of je account opheffen?</h3>
              <p className="margin-m-bottom">
                Heb je een maandabonnement en heb je besloten deze te willen beëindigen? Dit kun je op elk gewenst moment doen door eenvoudigweg je account te deactiveren in jouw online omgeving. Nadat je hebt opgezegd, is het niet meer mogelijk om loonaangiftes te doen.
                <br /><br />
                  Heb je een jaarabonnement? Dan kan je de overeenkomst opzeggen tot uiterlijk een maand voordat deze verloopt. Horen we niets van je, dan wordt het abonnement stilzwijgend verlengd met de duur van nog een jaar. Als je gedurende de abonnementsperiode wilt opzeggen, krijg je geen restitutie van het betaalde abonnementsgeld.
                <br /><br />
                  We kunnen je account altijd opzeggen. Hiervoor geldt een opzegtermijn van 1 maand.
                <br /><br />
                  Na het opzeggen van het abonnement sluiten we je account af en bewaren we je gegevens nog minimaal 3  maanden, zodat je de mogelijkheid hebt om de gegevens te downloaden.
              </p>

              <h3>8. Hoe kan je anderen toegang geven tot Employes?</h3>
              <p className="margin-m-bottom">Vanuit het hoofdaccount is het mogelijk om accounts voor andere gebruikers aan te maken. Als je bijvoorbeeld boekhouder bent, kan je accounts aanmaken voor je klanten. Ben je werkgever, dan kan je accounts aanmaken voor je medewerkers, zodat ze hun loonstroken kunnen inzien. Wees voorzichtig met het geven van bepaalde rechten. Als iemand gegevens verwijdert, kan je die niet meer terughalen.
              </p>

              <h3>9. Waar moet je op letten bij het gebruik van Employes?</h3>
              <p className="margin-m-bottom">Voor alle gebruikers geldt dat de login-gegevens strikt persoonlijk zijn en niet met andere personen mogen worden gedeeld. Alle gebruikers zijn zelf verantwoordelijk voor het geheimhouden van hun gebruikersnamen en wachtwoorden. Vanwege de veiligheid adviseren we om wachtwoorden regelmatig te wijzigen en te kiezen voor unieke wachtwoorden die alleen voor Employes worden gebruikt.
                <br /><br />
                  We adviseren je om de gegevens die je in Employes hebt ingevoerd, regelmatig te downloaden en op een andere plek op te slaan. We bieden geen standaard back-ups aan. Eenmaal verwijderde gegevens kunnen niet hersteld worden.
                <br /><br />

                Als je gebruik maakt van Employes, geef je de gegevens van je werknemers door in ons systeem. Deze worden volgens de Algemene Verordening Gegevensbescherming gezien als ‘persoonsgegevens’. Je bent dan verplicht om een ‘verwerkersovereenkomst’ met ons af te sluiten. Daarom is deze verwerkersovereenkomst op onze dienst van toepassing: <a href="https://s3-eu-west-1.amazonaws.com/files-public.employes.nl/Overeenkomst_verwerking_persoonsgegevens_employes.pdf">overeenkomst verwerking
                persoonsgegevens Employes</a>.
                <br /><br />



                  Het is niet toegestaan om illegale content op Employes te plaatsen. Daarmee bedoelen we  materialen die inbreuk maken op de intellectueel eigendomsrechten van derden, of content die in strijd is met de wet, openbare orde of goede zeden. Ook is het niet toegestaan om een account van Employes aan te maken met als doel om Employes geheel of gedeeltelijk na te bouwen.
                <br /><br />
                  Je staat ervoor in dat je bevoegd bent om namens het bedrijf aangifte(s) te doen.
                <br /><br />
                  Als je in strijd met de regels uit deze voorwaarden handelt en we worden door derden hiervoor aansprakelijk gesteld voor ontstane schade, dan vrijwaar je ons hiervoor. Daarnaast kunnen we je account bij overtreding per direct opheffen. We zijn niet aansprakelijk voor het verlies van data, of andere gegevens als je hierdoor geen toegang meer hebt tot die gegevens of gegevens door ons permanent verwijderd zijn.
                <br /><br />
                  We raden je aan om op tijd te verlonen in Employes, zodat je tijdig loonaangifte of andere aangiftes kunt doen bij de Belastingdienst of een pensioenfonds. Voor de aangiftes maken we een koppeling via een externe API. We hebben geen controle over deze API en bij een storing kan het gebeuren dat de aangifte niet op tijd binnen is. We zijn niet aansprakelijk voor eventuele boetes hiervoor. Je moet altijd zelf controleren of de aangifte op tijd binnen is gekomen.
              </p>

              <h3>10. Wat kan je bij je loonadministratie van ons verwachten?</h3>
              <p className="margin-m-bottom">We willen het gebruik van Employes zo eenvoudig mogelijk maken, zodat je zelf in staat bent om je loonadministratie te doen. Daarom vind je op onze website, bijvoorbeeld in de kennisbank, diverse blogs met tips over hoe je een loonadministratie kunt inrichten. Ook kan je van onze supportdesk algemene tips krijgen over het inrichten van je administratie.
                <br /><br />
                  Deze blogs en tips zijn met de nodige zorg samengesteld, maar kunnen niet ter vervanging dienen van een persoonlijk advies van een loonadministratie-expert over jouw specifieke situatie.
                <br /><br />
                  Hoe je je loonadministratie het best kunt inrichten, is namelijk afhankelijk van diverse individuele omstandigheden. Bijvoorbeeld bij welke cao of pensioenregeling je verplicht bent om je aan te sluiten. Laat je na om advies te vragen aan een expert en doe je de administratie zelf? Dan is dit voor eigen risico en kan je ons niet aansprakelijk stellen voor eventuele schade die hierbij ontstaat.
              </p>

              <h3>11. Wat kan je van onze overstap- en klantenservice verwachten?</h3>
              <p className="margin-m-bottom">Als je gebruikmaakt van onze overstapservice, dan voeren we die dienst altijd uit op basis van de informatie die je ons gegeven hebt. We doen ons best om dit zo goed mogelijk te doen, maar we kunnen niet beloven dat het resultaat altijd foutloos is. Je moet de door ons ingevoerde gegevens daarom altijd controleren. Zijn de gegevens niet correct? Dan is dit voor eigen risico en kan je ons niet aansprakelijk stellen voor schade die hierbij is ontstaan.
                <br /><br />
                  Supportvragen beantwoorden we alleen op werkdagen tussen 9:00 en 17:00 uur. We doen ons best om zo spoedig mogelijk te reageren, maar spreken nooit reactietermijnen af.
              </p>

              <h3>12. Wat kan je technisch gezien van ons systeem verwachten?</h3>
              <p className="margin-m-bottom">We bieden Employes aan zoals het is, met de functionaliteiten die hierbij horen op het moment van aanbieden. Functionaliteiten kunnen wijzigen en het kan zijn dat we op een gegeven moment een bepaald onderdeel niet meer aanbieden.
                <br /><br />
                  Employes werkt op de meest recente versies van internetbrowsers, bijvoorbeeld Google Chrome. We adviseren je om te werken met de nieuwste browserversie en deze regelmatig te updaten. We kunnen namelijk niet garanderen dat Employes volledig op oude browsers werkt.
                <br /><br />
                  We spannen ons in voor een optimale beschikbaarheid en bereikbaarheid van Employes, maar we kunnen niet garanderen dat Employes altijd (volledig) beschikbaar is. Zo kan Employes tijdelijk niet beschikbaar zijn als we bijvoorbeeld onderhoud uitvoeren, bugs oplossen of updates doorvoeren.
                <br /><br />
                  Onderhoud proberen we altijd uit te voeren op tijden waarop zo min mogelijk gebruikers er last van hebben.
                <br /><br />
                  We doen ons best om Employes zonder fouten te laten werken en fouten zo spoedig mogelijk te herstellen. Maar we kunnen niet garanderen dat deze fouten altijd hersteld worden, of dat Employes geen fouten bevat.
                <br /><br />
                  We hebben het recht om Employes permanent stop te zetten. Als we dat doen, kondigen we dat minimaal 6 maanden van tevoren aan.
              </p>

              <h3>13. Hoe gaan we om met problemen en met aansprakelijkheid?</h3>
              <p className="margin-m-bottom">Als er iets mis gaat, horen we het graag. Dan zoeken we samen naar een oplossing.
                <br /><br />
                  Ook een geschil lossen we bij voorkeur samen op. Lukt dat niet? Dan leggen we ons geschil voor aan de rechter te Amsterdam. Het Nederlands recht geldt hiervoor.
                <br /><br />
                  Als je ons aansprakelijk wilt stellen, dan ontvangen we graag eerst een bericht (een ‘ingebrekestelling’). Daarin omschrijf je waarom je ons aansprakelijk wilt stellen en geef je ons de kans om eventuele schade of fouten te herstellen. Hiervoor geef je ons altijd een redelijke termijn.
                <br /><br />
                  Blijkt dat wij voor schade aansprakelijk zijn wegens een toerekenbare tekortkoming in de nakoming van de overeenkomst of op een andere wijze? Dan is onze totale aansprakelijkheid beperkt tot vergoeding van de directe schade, tot maximaal het bedrag dat je in een jaar aan ons hebt betaald of zou betalen voor het gebruik van Employes. Als we voor die schade verzekerd zijn, beperkt onze aansprakelijkheid zich tot het bedrag dat onze verzekeraar uitkeert.
                <br /><br />
                  We zijn nooit aansprakelijk voor indirecte schade, waaronder gevolgschade, gederfde winst, gemiste besparingen en schade door bedrijfsstagnatie.
                <br /><br />
                  Deze beperking geldt niet als de schade veroorzaakt is door opzet of grove schuld van onze kant.
                <br /><br />
                  Iedere vordering tot aansprakelijkheid vervalt automatisch na 24 maanden nadat de vordering is ontstaan.
              </p>

              <h3>14. Wat doen we als er sprake is van overmacht?</h3>
              <p className="margin-m-bottom">Als er sprake is van overmacht, hoeven we de verplichtingen uit de overeenkomst niet na te komen. Onder overmacht verstaan we gebrek aan of stagnatie in de dienstverlening ontstaan door ingeschakelde derden, externe API’s die niet of niet volledig werken of het niet kunnen leveren door stroom- en/of telecommunicatievoorzieningen.
                <br /><br />
                  Als de overmachtsituatie langer dan 60 dagen duurt, heb je het recht om je abonnement tussentijds te beëindigen. We zijn dan niet aansprakelijk voor schade hiervoor. Wel ontvang je restitutie van al betaalde abonnementsgelden naar rato van de niet gebruikte maanden.
              </p>

              <h3>15. Wanneer kunnen we deze voorwaarden wijzigen?</h3>
              <p>We kunnen onze algemene voorwaarden altijd wijzigen. Als we dat doen, sturen we je onze nieuwe voorwaarden 2 maanden voor de ingangsdatum toe.
                <br /><br />
                  Als je na een wijziging van de algemene voorwaarden gebruik blijft maken van Employes, ga je akkoord met de inhoud hiervan.
                <br /><br />
                  Versie 1 december 2022
                  <br /><br />

                  Downloads:<br />
                  <a href="https://s3.eu-west-1.amazonaws.com/files-public.employes.nl/Algemene_voorwaarden_Employes.pdf">Algemene voorwaarden (pdf)</a><br />
                  <a href="https://s3-eu-west-1.amazonaws.com/files-public.employes.nl/Overeenkomst_verwerking_persoonsgegevens_employes.pdf">Overeenkomst verwerking persoonsgegevens (pdf)</a>.
                  
              </p>

            </div>
          </div>

          {/*
            <div className="grid between">
              <div className="col-6">
                <img src="https://via.placeholder.com/568x408" className="block" />
              </div>

              <div className="col-6">
                <img src="https://via.placeholder.com/568x408" className="right block" />
              </div>
            </div>
          */}
        </div>
      </section>

    </main>
  </Layout>
)

export default AlgemeneVoorwaardenPage
